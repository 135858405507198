import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useLinkChunks } from 'hooks/use-link-chunks';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { array, string } from 'prop-types';
import SolutionsIllustration from 'svgs/expertises/solutions.svg';

import { ExpandableContent } from './components/expandable-content';

const SContainer = styled.div`
    background-color: var(--gray-color-90);
    padding: 4.125rem 5rem;
    display: flex;
    gap: 4.375rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
    `}
`;

const SLeftCol = styled.div`
    flex: 0.4;
    width: 100%;
    height: 100%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;

    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
    `}
`;

const SLeftTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
`;

const SSolutionsIllustration = styled(SolutionsIllustration)`
    width: 100%;
    height: 100%;
    max-width: 28.125rem;
    max-height: 23.75rem;
`;

const STextWrapper = styled.div`
    flex: 0.6;
`;

const SNumber = styled.span`
    font-size: 2.125rem;
    text-align: end;
`;

const SSolution = styled.div`
    display: grid;
    grid-template-columns: 3.25rem 1fr;
    column-gap: 1.125rem;
    align-items: baseline;
    margin-bottom: 3.75rem;
`;

const STitle = styled(HeaderSecond)`
    font-size: 1.375rem;
    font-weight: 400;
`;

const SParagraph = styled(Paragraph)`
    grid-column: 2 / 4;
    white-space: pre-wrap;

    ${CONSTANTS.MEDIA.max768`
        white-space: unset;
    `}
`;

export const PerksWithImageSection = ({ title, perks, description, links }) => {
    const { formatMessage } = useIntl();
    const linkChunks = useLinkChunks(links);
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <Container>
            <SContainer>
                <SLeftCol>
                    <SSolutionsIllustration />
                    <SLeftTextContainer>
                        <HeaderSecond
                            id={kebabCaseId}
                            handleCopyUrl={handleCopyUrl}
                        >
                            <FormattedMessage id={title} />
                        </HeaderSecond>
                        {description && <FormattedMessage id={description} />}
                    </SLeftTextContainer>
                </SLeftCol>
                <STextWrapper>
                    {perks.map((item, idx) => {
                        const { title, text, details } = item;
                        return (
                            <SSolution key={title}>
                                <SNumber>{`0${idx + 1}.`}</SNumber>
                                <STitle>
                                    <FormattedMessage id={title} />
                                </STitle>
                                <SParagraph>
                                    {formatMessage({ id: text }, linkChunks)}
                                    {details?.length > 0 && (
                                        <ExpandableContent data={details} />
                                    )}
                                </SParagraph>
                            </SSolution>
                        );
                    })}
                </STextWrapper>
            </SContainer>
        </Container>
    );
};

PerksWithImageSection.propTypes = {
    title: string.isRequired,
    description: string,
    perks: array.isRequired,
};
